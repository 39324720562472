<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div v-if="props.total > 0" style="margin-top:12px;display:inline-block;float:none;">
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      background
       hide-on-single-page
      :page-sizes="props.pageSizes"
      :layout="props.layout"
      :total="props.total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<!---分页组件-->
<script setup>
import { scrollTo } from '@/utils/scrollTo';
import { computed } from 'vue';
const emits = defineEmits(['pagination','update:page','update:limit']),

  props = defineProps({
    total: { required: true,type: Number, default: 0 },
    page: { type: Number,default: 1},
    limit: { type: Number,default: 20},
    pageSizes: {type: Array,default() {
      return [10, 20, 30, 50, 100];
    }},
    layout: {type: String,default: 'total, sizes, prev, pager, next, jumper'},
    autoScroll: {type: Boolean,default: true},
    hidden: {type: Boolean,default: false}
  });

// eslint-disable-next-line one-var
let currentPage = computed({
    get:()=>{
      return props.page;
    },
    set:(val)=>{
      emits('update:page', val);
    }
  }),
  //每页查询长度
  pageSize = computed({
    get() {
      return props.limit;
    },
    set(val) {
      emits('update:limit', val);
    }
  });
//当前页码改变监听
// eslint-disable-next-line one-var
const handleCurrentChange = (val)=>{
    emits('pagination', { page: val, limit: pageSize });
    if (props.autoScroll) {
      scrollTo(0, 800);
    }
  },
  //每页查询长度监听
  handleSizeChange = (val)=>{
    emits('pagination', { page: currentPage, limit: val });
    if (props.autoScroll) {
      scrollTo(0, 800);
    }
  };

</script>

