import request from '@/utils/request';
/** 用户登录操作 */
export function doLogin (loginParam) {
  return request({
    url: `/login`,
    method: 'post',
    data: loginParam
  });
}
/** 用户登出 */
export function logout () {
  return request({
    url: `/loginOut`,
    method: 'get'
  });
}
/** 获取登录用户信息 */
export function getUserInfo () {
  return request({
    url: `/getLoginUserInfo`,
    method: 'get'
  });
}
/** 获取图形验证码 */
export function getVerifyCode (mobile) {
  return request({
    url: `/sys/verify/getImgCode`,
    method: 'get',
    params: mobile
  });
}
/** 获取数字验证码 */
export function getNumberCode (mobile) {
  return request({
    url: `/sys/verify/getNumberCode`,
    method: 'get',
    params: mobile
  });
}

/** 获取手机验证码 */
export function getMobileVerifyCode (mobile, bizType) {
  return request({
    url: `/verify/getShortMessageCode?mobile=` + mobile + `&bizType=` + bizType,
    method: 'get'
  });
}
// 根据原密码修改密码
export function updatepassword (data) {
  return request({
    url: `/sys/user/updatePassWord`,
    method: 'post',
    data: data
  });
}
// 根据原密码修改密码
export function getBatchShortMessageCode () {
  return request({
    url: `/verify/getBatchShortMessageCode`,
    method: 'get'
  });
}

// 忘记密码
export function forgetPassword (data) {
  return request({
    url: `/sys/user/forgetPassword`,
    method: 'post',
    data: data
  });
}
