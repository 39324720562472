import { createApp } from 'vue';
import App from './App.vue';
const app = createApp(App);

/**引入ElementPlus */
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import locale from 'element-plus/es/locale/lang/zh-cn';
app.use(ElementPlus, { locale });
import * as Elicons from "@element-plus/icons-vue";
for (const name in Elicons) {
  app.component(name, Elicons[name]);
}

app.config.globalProperties.$icons = [];
for (const [key, component] of Object.entries(Elicons)) {
  app.config.globalProperties.$icons.push(key);
  app.component(key, component);
}
/**等比例缩放 */
import '@/rem.js';
/**公共样式 */
import '@/styles/index.scss';
/**路由 */
import router from './router';
app.use(router);
import store from './store';
app.use(store);
/**引入粒子动效插件 */
import VueParticles from 'vue-particles';
app.use(VueParticles);
/**引入JSON显示组件 */
//添加样式
import "vue3-json-viewer/dist/index.css";
import JsonViewer from "vue3-json-viewer";
app.use(JsonViewer);
/**引入echarts */
import * as echarts from 'echarts';
app.echarts = echarts;
/** 配置需要全局引入的组件 */
import TableQuery from '@/components/table/TableQuery.vue';
import TableDict from '@/components/table/TableDict.vue';
import TableImage from '@/components/table/TableImage.vue';
import Pagination from '@/components/form/Pagination.vue';
import CascaderSelect from '@/components/form/CascaderSelect.vue';
import OrgSelect from '@/components/form/OrgSelect.vue';
import Dialog from "./components/form/Dialog.vue";
import DictSelect from "./components/form/DictSelect.vue";
import DictRadio from "./components/form/DictRadio.vue";
import UploadFile from '@/components/form/UploadFile.vue';
import TableAdmin from "./components/table/TableAdmin.vue";
import FormSelect from "./components/form/FormSelect.vue";
import AdminSelect from "./components/form/AdminSelect.vue";
app.component('UploadFile', UploadFile);
app.component('Pagination', Pagination);
app.component('TableQuery', TableQuery);
app.component('TableImage', TableImage);
app.component('TableDict', TableDict);
app.component('CascaderSelect', CascaderSelect);
app.component('Dialog', Dialog);
app.component('DictSelect', DictSelect);
app.component('DictRadio', DictRadio);
app.component('TableAdmin', TableAdmin);
app.component('FormSelect', FormSelect);
app.component('AdminSelect', AdminSelect);
app.component('OrgSelect', OrgSelect);


/**自定义指令 */
/** 按钮权限使用方法
 * 直接在标签上添加v-bp.value="`code`"  或者v-if="$_bp(code)""
 * code为按钮权限编码
 */
app.directive('bp', {
  mounted (el, binding, vnode) {
    const permissionList = router.currentRoute.value.meta.permission;
    // 按钮权限 调用方法

    if (!permissionList || permissionList.length <= 0) {
      el.parentNode.removeChild(el);
    } else if (undefined === permissionList.find(r => r === binding.value)) {
      el.parentNode.removeChild(el);
    }
  }
});


/**全局挂载 */
app.config.globalProperties.$apiLoding = require('@/utils/apiLoding');
/**挂载 */
app.mount('#app');
