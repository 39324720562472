<!-- 机构级联选择器 -->
<template>
  <el-form-item
    v-if="uerType === '0'"
    :label="props.label"
    :label-width="props.labelWidth"
    :required="props.required"
  >
    <el-cascader
      ref="orgCascader"
      v-model="selectedOptions"
      :class="props.className"
      :options="options"
      clearable
      :disabled="props.disabled"
      filterable
      placeholder="请选择机构列表"
      :props="{
        emitPath: false,
        checkStrictly: true,
        multiple: props.multiple,
        value: 'orgCode',
        label: 'name',
        children: 'children'
      }"
      @change="handleChange"
    />
  </el-form-item>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { getOrgTree } from '@/utils/auth';
const store = useStore(),
  emits = defineEmits(['update:modelValue', 'change']),
  props = defineProps({
    modelValue: {
      type: String,
      default: ''
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    //是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String,
      default: '110px'
    },
    //label 标题
    label: {
      type: String,
      default: '所属机构'
    },
    //必填的时候传入
    required: {
      type: Boolean,
      default: false
    },
    //管理员编码
    adminCode: {
      type: String,
      default: ''
    },
    //类型 查询：st-query-input-width 表单：st-input-width
    className: {
      type: String,
      default: 'st-query-input-width'
    }
  }),
  uerType = ref(store.getters.userType), //当前登录用户类型
  orgCascader = ref(), //ref
  options = ref([]), //数据数组
  selectedOptions = ref(); //当前选择对象值

let list = ref([]);
//选择改变监听
// eslint-disable-next-line one-var
const handleChange = () => {
  const seslectedList = orgCascader.value.getCheckedNodes();

  if (seslectedList) {
    if (props.multiple) {
      let data = [];

      seslectedList.forEach((r) => {
        data.push(r);
      });
      emits('change', data, selectedOptions);
    } else if (seslectedList.length > 0) {
      emits('change', seslectedList[0].data, seslectedList[0].level);
    }
  }
};

onMounted(() => {
  list.value = getOrgTree();
  console.log('机构树状列表', list.value);
  options.value = list.value;
  selectedOptions.value = props.modelValue;
});

//监听函数
watch(
  () => props.modelValue,
  (newValue) => {
    selectedOptions.value = newValue;
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
//监听函数
watch(
  () => selectedOptions.value,
  (newValue) => {
    emits('update:modelValue', newValue);
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);

//根据机构编码过滤
watch(
  () => props.adminCode,
  (newValue) => {
    if (newValue) {
      if (list.value && list.value.length > 0) {
        options.value = list.value.filter(
          (item) => newValue === item.adminCode
        );
      }
    } else {
      options.value = list.value;
    }
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
</script>
