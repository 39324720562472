<!-- 级联选择器 -->
<template>
  <el-cascader
    ref="orgCascader"
    v-model="selectedOptions"
    v-loading="queryData.loading"
    :class="className"
    :options="options"
    clearable
    :disabled="disabled"
    filterable
    :placeholder="placeholder"
    :props="{
      emitPath: false,
      checkStrictly: checkStrictly,
      multiple: multiple,
      value: value,
      label: label,
      children: children
    }"
    @change="handleChange"
  />
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import queryEntity from '@/model/entity/framework/queryEntity';

const store = useStore(),
  emits = defineEmits(['update:modelValue', 'change']),
  props = defineProps({
    //是否可操作
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    //选中对象的值
    modelValue: {
      type: String,
      default: null
    },
    //设置父子节点取消选中关联
    checkStrictly: {
      type: Boolean,
      default: true
    },
    //是否多选
    multiple: {
      type: Boolean,
      default: false
    },
    //请求接口地址 例如 `/sys/organization/pageTree`
    api: {
      required: true,
      type: String,
      default: null
    },
    //查询条件的key
    queryKey: {
      required: false,
      type: String,
      default: null
    },
    //查询条件的value
    queryValue: {
      required: false,
      type: String,
      default: null
    },
    //显示key值
    value: {
      type: String,
      default: 'id'
    },
    //显示标签值
    label: {
      type: String,
      default: 'name'
    },
    //children 子项
    children: {
      type: String,
      default: 'children'
    },
    //是否重新请求接口
    refresh: {
      type: Boolean,
      default: false
    },
    //类型 查询：st-query-input-width 表单：st-input-width
    className: {
      type: String,
      default: 'st-query-input-width'
    }
  }),
  orgCascader = ref(), //ref
  refresh = ref(props.refresh), //是否重新请求接口
  options = ref([]), //数据数组
  selectedOptions = ref(), //当前选择对象值
  queryData = queryEntity.parse({}),
  /**	获取树桩列表数据*/
  initData = async () => {
    let info = store.getters.getDatatList(props.api);

    if (!refresh.value && info && info.records && info.records.length > 0) {
      options.value = info.records;
    } else {
      let params = {
        api: props.api,
        refresh: refresh.value
      };

      if (props.queryKey && props.queryValue) {
        const keyList = props.queryKey.split(','),
          valueList = props.queryValue.split(',');

        if (
          keyList &&
          keyList.length > 0 &&
          valueList &&
          valueList.length > 0 &&
          keyList.length === valueList.length
        ) {
          // eslint-disable-next-line no-undef
          keyList.forEach((key, index) => {
            if ('adminCode' === key || 'orgCode' === key) {
              queryData[key] = valueList[index];
            } else {
              queryData.queryEntity[key] = valueList[index];
            }
          });
        }
        // eslint-disable-next-line no-dupe-else-if
      } else if (props.queryKey && props.queryValue === '') {
        const keyList = props.queryKey.split(','),
          valueList = props.queryValue.split(',');

        keyList.forEach((key, index) => {
          queryData.queryEntity[key] = valueList[index];
        });
      }
      params.queryData = queryData;
      const { data } = await store.dispatch('GetDatatList', params);

      data.records.forEach((item) => {
        if (item.orgCode) {
          options.value = data.records;
        }
      });
    }
  },
  handleChange = () => {
    const seslectedList = orgCascader.value.getCheckedNodes();

    if (seslectedList) {
      if (props.multiple) {
        let data = [];

        seslectedList.forEach((r) => {
          data.push(r);
        });
        emits('change', data, selectedOptions);
      } else if (seslectedList.length > 0) {
        emits('change', seslectedList[0].data, seslectedList[0].level);
      }
    }
  };

watch(
  () => props.queryValue,
  (newValue) => {
    initData();
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);

//监听函数
// eslint-disable-next-line one-var
watch(
  () => props.modelValue,
  (newValue) => {
    selectedOptions.value = newValue;
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
watch(
  () => selectedOptions.value,
  (newValue) => {
    emits('update:modelValue', newValue);
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);

onMounted(() => {
  initData();
  selectedOptions.value = props.modelValue;
});
</script>
<style lang="scss" scoped></style>
