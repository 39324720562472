<!-- 所属管理员组件 -->
<template>
  <el-form-item v-if="uerType === '0'" :label="label" :label-width="labelWidth">
    <el-select
      v-model="val"
      clearable
      filterable
      :class="className"
      placeholder="请选择所属管理员"
    >
      <el-option
        v-for="(item, key) in list"
        :key="key"
        :label="item.name"
        :value="item.adminCode"
      />
    </el-select>
  </el-form-item>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { getAdminList } from '@/utils/auth';
const store = useStore(),
  emits = defineEmits(['update:modelValue', 'change']),
  props = defineProps({
    modelValue: {
      type: String,
      default: ''
    },
    labelWidth: {
      type: String,
      default: '93px'
    },
    //label 标题
    label: {
      type: String,
      default: '所属管理员'
    },
    prop: {
      //传入字段，需要显示必填时请填入adminCode
      type: String,
      default: ''
    },
    //类型 查询：st-query-input-width 表单：st-input-width
    className: {
      type: String,
      default: 'st-query-input-width'
    }
  }),
  val = ref(),
  list = ref(), //获取系统管理员列表
  uerType = ref(store.getters.userType); //当前登录用户类型

onMounted(() => {
  list.value = getAdminList();
  val.value = props.modelValue;
});
//监听函数
watch(
  () => props.modelValue,
  (newValue) => {
    val.value = newValue;
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
//监听函数
watch(
  () => val.value,
  (newValue) => {
    emits('update:modelValue', newValue);
  },
  {
    immediate: false, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
</script>
