const getters = {
  loginUserInfo: state => state.user.userInfo,
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  userType: state => state.user.userInfo.type,
  resources: state => state.user.userInfo.resources,
  bgColor: state => state.permission.bgColor,
  collapse: state => state.permission.collapse
};

export default getters;
