<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable newline-after-var -->
<!-- eslint-disable newline-after-var -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <el-upload
    ref="upload"
    v-model="fileIds"
    v-model:file-list="fileList"
    :disabled="props.disabled"
    :list-type="props.listType"
    :multiple="props.multiple"
    :limit="props.limit"
    class="upload-demo app-upload"
    :action="actionPath"
    :data="formData"
    :before-upload="beforeUpload"
    :on-success="handleSuccess"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :before-remove="beforeRemove"
    :on-exceed="handleExceed"
  >
    <div v-if="showButton">
      <el-button type="primary"> 上传 </el-button>
    </div>
    <el-icon v-else>
      <Plus />
    </el-icon>
    <template #tip>
      <div class="el-upload__tip text-red">文件上传尺寸必须小于2M</div>
    </template>
    <template v-if="props.listType !== 'text'" #file="{ file }">
      <div>
        <!-- <img class="el-upload-list__item-thumbnail" :src="!!file.fullUrl ? file.fullUrl : file.url" alt="请上传图片" /> -->
        <img
          class="el-upload-list__item-thumbnail"
          :src="fileUrl + file.url"
          alt="请上传图片"
        />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePreview(file)"
          >
            <el-icon><zoom-in /></el-icon>
          </span>
          <span
            class="el-upload-list__item-delete"
            @click="handlePictureDownload(file)"
          >
            <el-icon><Download /></el-icon>
          </span>
          <span
            class="el-upload-list__item-delete"
            @click="handlePictureRemove(file, fileList)"
          >
            <el-icon><Delete /></el-icon>
          </span>
        </span>
      </div>
    </template>
  </el-upload>

  <el-dialog v-model="dialogVisible">
    <img
      w-full
      :src="dialogImageUrl"
      alt="Preview Image"
      class="bigHeadPortrait"
    />
  </el-dialog>
</template>
<script setup>
import { ref, reactive, toRaw, watch, getCurrentInstance } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';

import { downFileById } from '@/api/sys/fileInfo';

const emits = defineEmits(['update:modelValue', 'headPortraitData']),
  fileUrl = process.env.VUE_APP_FILE_API,
  { proxy } = getCurrentInstance(),
  //父组件传入子组件的值
  props = defineProps({
    //选中对象的值
    modelValue: {
      type: String,
      default: null
    },
    limit: {
      //上传文件限制
      type: Number,
      default: 1
    },
    fileList: {
      //文件数组
      type: Array,
      default() {
        return [];
      }
    },
    listType: {
      // 文件列表的类型 "text" | "picture" | "picture-card"
      type: String,
      default: 'text'
    },
    multiple: {
      //是否支持多选文件
      type: Boolean,
      default: false
    },
    //是否可点击
    disabled: {
      type: Boolean,
      default: false
    },
    bizId: {
      //关联业务id
      type: String,
      default: null
    },
    bizType: {
      //业务类型
      type: String,
      default: null
    }
  });

let fileList = ref([]),
  fileIds = ref([]), //图片id数组
  showButton = ref(true), //是否,
  dialogVisible = ref(false), //扩大查看图片
  dialogImageUrl = ref(), //浴帘图片地址
  formData = reactive({
    file: null, //文件
    headPortrait: ''
  }),
  actionPath = ref(process.env.VUE_APP_BASE_API + '/sys/fileInfo/upload');

watch(
  () => props.listType,
  (newValue, oldValue) => {
    if (newValue && newValue === 'picture-card') {
      showButton.value = false;
    }
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
//监听父组件传如附件数组的变化
watch(
  () => props.fileList,
  (newValue, oldValue) => {
    if (newValue && newValue.length > 0) {
      fileList.value = newValue;
      fileIds.value = newValue.map((element) => element.id);
      emits('update:modelValue', fileIds.value);
    } else {
      fileList.value = [];
      emits('update:modelValue', []);
    }
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);

//文件上传成功回调
// eslint-disable-next-line one-var, newline-after-var
const handleSuccess = (response, file, list) => {
    file.url = file.response.data.url;
    formData.headPortrait = file.response.data.url;
    emits('headPortraitData', file.response.data.url);
    if (list && list.length > 0) {
      const rawList = toRaw(list);

      fileList.value = rawList;
      fileIds.value = rawList.map((element) => element.response.data.id);
      emits('update:modelValue', fileIds.value);
    }
  },
  beforeUpload = (file) => {
    if (props.bizId) {
      formData.bizId = props.bizId;
    }
    if (props.bizType) {
      formData.bizType = props.bizType;
    }
    formData.file = file;
    //jpeg jpg png doc docx xls  xlsx
    const type = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    if (type.indexOf(file.type) === -1) {
      ElMessage.error('上传的文件必须是jpeg jpg png doc docx xls  xlsx 之一');
      return false;
    } else if (file.size / 1024 / 1024 > 50) {
      ElMessage.error('图片大小不能超过50MB');
      return false;
    }
    return true;
  },
  // 文件列表移除文件时的钩子
  handleRemove = (file, uploadFiles) => {
    if (file) {
      const data = toRaw(file.response.data),
        newFileIds = fileIds.value.filter((r) => r !== data.id);

      fileIds.value = newFileIds;
    }
  },
  // 文件下载

  download = (id) => {
    proxy.$apiLoding.queryLoding(id, downFileById, false);
  },
  //点击文件列表中已上传的文件时的钩子

  handlePreview = (file) => {
    if (file) {
      if (props.listType === 'picture-card') {
        dialogVisible.value = true;
        dialogImageUrl.value = fileUrl + file.url;
      } else {
        const data = toRaw(file.response.data);

        download(data.id);
      }
    }
  },
  //当超出限制时，执行的钩子函数
  handleExceed = (files, uploadFiles) => {
    ElMessage.warning(`最多允许上传 ${files.length} 个文件`);
  },
  beforeRemove = (uploadFile, uploadFiles) => {
    return ElMessageBox.confirm(`确定删除文件 ${uploadFile.name} 吗?`).then(
      () => true,
      () => false
    );
  },
  //图片操作
  //下载图片
  handlePictureDownload = (file) => {
    if (file) {
      const data = toRaw(file);

      download(data.id);
    }
  },
  //移除图片
  handlePictureRemove = (file, list) => {
    if (file && list) {
      const dataList = toRaw(list),
        data = toRaw(file),
        newList = dataList.filter((element) => element.id !== data.id),
        newFileIds = fileIds.value.filter((r) => r !== data.id);

      fileList.value = newList;
      fileIds.value = newFileIds;
    }
  };
</script>

<style lang="scss" scoped>
.app-upload {
  width: 500px !important;
}
.bigHeadPortrait {
  width: 100%;
  height: 100%;
}
</style>
