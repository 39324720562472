/* eslint-disable one-var */
export const TokenKey = 'Authorization'; //登录
export const adminKey = 'admin'; //系统管理员
export const orgTreeKey = 'orgTree'; //系统管理员

import ase from '@/utils/ase';
export function getToken () {
  const token = localStorage.getItem(ase.encrypt(TokenKey));

  if (token) {
    return ase.decrypt(token);
  }
  return token;

}

export function setToken (token) {
  return localStorage.setItem(ase.encrypt(TokenKey), ase.encrypt(token));
}

export function removeToken () {
  return localStorage.removeItem(ase.encrypt(TokenKey));
}

/**系统管理员列表 */
export function getAdminList () {
  const list = localStorage.getItem(ase.encrypt(adminKey));

  if (list) {
    return JSON.parse(ase.decrypt(list));
  }

  return list;

}


export function setAdminList (list) {
  return localStorage.setItem(ase.encrypt(adminKey), ase.encrypt(JSON.stringify(list)));
}

export function removeAdminList () {
  return localStorage.removeItem(ase.encrypt(adminKey));
}


/**机构树状列表 */
export function getOrgTree () {
  const list = localStorage.getItem(ase.encrypt(orgTreeKey));

  if (list) {
    return JSON.parse(ase.decrypt(list));
  }
  return list;

}

export function setOrgTree (list) {
  return localStorage.setItem(ase.encrypt(orgTreeKey), ase.encrypt(JSON.stringify(list)));
}

export function removeOrgTree () {
  return localStorage.removeItem(ase.encrypt(orgTreeKey));
}

