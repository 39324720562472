import request from '@/utils/request';
//获取列表数据
const dataStore = {
  state: {
    // 数据列表
    datalist: [],
    // 请求的api接口编码数组(例如：模块名_QUERY_LIST (USER_QUERY_LIST))
    apiCodeList: []
  },
  mutations: {
    //添加api到数组
    PUSH_DATA_API: (state, data) => {
      if (data) {
        const apiCode = state.apiCodeList.find(r => data === r);

        if (undefined === apiCode) {
          state.apiCodeList.push(data);
        }
      }

    },
    //  添加接口数据到数组
    PUSH_LIST: (state, data) => {
      if (data && data.apiCode) {
        const apiCode = state.apiCodeList.find(r => data.apiCode === r);

        if (undefined === apiCode) {
          state.datalist.push(data);
        }
      }
    }
  },
  getters: {
    //   获取数据
    getDatatList: (state) => (data) => {
      const entity = state.datalist.find(r => r.apiCode === data);

      return entity ? entity.list : null;
    }
  },
  actions: {
    // 获取数据列表
    GetDatatList ({ commit, state }, params) {
      if (params.api) {
        const codeData = state.apiCodeList.find(r => params.apiCode === r);

        let b = params.refresh;

        if (!b) {
          b = undefined === codeData && undefined === state.datalist.find(r => r === params.api);
        }
        if (b) {
          return new Promise((resolve, reject) => {
            //请求接口数据
            request({
              url: params.api,
              method: 'post',
              data: params.queryData
            }).then(res => {
              if (res.data) {
                commit('PUSH_LIST', { apiCode: params.apiCode, list: res.data });
                commit('PUSH_DATA_API', params.api);
                resolve(res);
              } else {
                reject(res);
              }
            });
          });
        }
      }
    }

  }

};

export default dataStore;
