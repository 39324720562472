
import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse('1234567890abcdef');

export default {
  // 加密
  encrypt(word) {
    const srcs = CryptoJS.enc.Utf8.parse(word),
      encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  },
  // 解密
  decrypt(word) {
    const decrypt = CryptoJS.AES.decrypt(word, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });

    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  }
};
