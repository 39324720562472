/* eslint-disable indent */
/* eslint-disable no-shadow */
import { doLogin, logout, getUserInfo } from '@/api/sys/login';
import { setToken, removeToken, setAdminList, removeAdminList, setOrgTree, removeOrgTree } from '@/utils/auth';
import { ElMessage, ElLoading } from 'element-plus';
import router from '@/router';
// import ase from '@/utils/ase.js'
import { encrypt } from '@/utils/jsencrypt';
import store from '@/store';
const state = {
  userInfo: null
},
  mutations = {
    SET_USER_INFO: (state, info) => {
      state.userInfo = info;
    }
  },
  actions = {
    /** 用户登录 */
    doLogin ({ commit }, userInfo) {
      const { userName, passWord } = userInfo,
        formData = { ...userInfo };

      commit('SET_USER_INFO', null);
      formData.userName = encrypt(userName.trim());
      formData.passWord = encrypt(passWord);
      return new Promise((resolve, reject) => {
        doLogin(formData).then(res => {
          if (res.success) {
            setToken(res.data.token);
            setAdminList(res.data.adminList);
            setOrgTree(res.data.organizationList);
            store.dispatch('SetSysDictList', res.data.dictionaryList);
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(error => {
          reject(error);
        });
      });
    },

    /** 获取用户信息 */
    getInfo ({ commit, state }) {
      const loading = ElLoading.service({
        lock: true,
        text: '正在获取用户信息...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          if (res.success) {
            commit('SET_USER_INFO', res.data);
            setAdminList(res.data.adminList);
            setOrgTree(res.data.organizationList);
            resolve(res.data);
            loading.close();
          } else {
            commit('SET_USER_INFO', null);
            removeToken();
            reject(res);
            loading.close();
          }
        });
      });
    },

    /** 用户登出 */
    logout ({ commit, state }) {
      const loading = ElLoading.service({
        lock: true,
        text: '正在退出系统,请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      return new Promise((resolve, reject) => {
        logout().then(() => {
          removeToken();
          removeAdminList();
          removeOrgTree();
          ElMessage.success('退出成功');
          commit('SET_USER_INFO', null);
          router.push(`/login`);
          location.reload();
          loading.close();
          resolve();
        }).catch(error => {
          loading.close();
          reject(error);
        });
      });
    },

    // remove token
    resetToken ({ commit }) {
      return new Promise(resolve => {
        removeToken();
        removeAdminList();
        removeOrgTree();
        commit('SET_USER_INFO', {});
        router.push(`/login`);
        resolve();
      });
    }
  };

export default {
  namespaced: true,
  state,
  mutations,
  actions
};

