<!-- 数据字典下拉选择框 -->
<template>
  <el-select
    v-model="val"
    :readonly="readonly"
    :size="size"
    :disabled="disabled"
    :multiple="multiple"
    :style="{ width: width }"
    :clearable="clearable"
    filterable
    :class="className"
    :placeholder="placeholder"
  >
    <template v-for="(item, key) in list">
      <template v-if="needVlas.length > 0">
        <el-option
          v-if="undefined !== needVlas.find((r) => r === item[props.valueName])"
          :key="key"
          :label="item.name"
          :value="item[props.valueName]"
        />
      </template>
      <template v-else>
        <el-option
          v-if="
            undefined === noNeedVals.find((r) => r === item[props.valueName])
          "
          :key="key"
          :label="item.name"
          :value="item[props.valueName]"
        />
      </template>
    </template>
  </el-select>
</template>
<script setup>
import { ref, watch, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
const store = useStore(),
  emits = defineEmits(['update:modelValue', 'change', 'selectChange']),
  props = defineProps({
    modelValue: {
      type: [String, Array],
      default: null
    },
    clearable: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    needVlas: {
      type: Array,
      default: () => []
    },
    noNeedVals: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    valueName: {
      type: String,
      default: 'value'
    }
  }),
  val = ref(),
  list = ref([]);

// eslint-disable-next-line one-var
const initData = async () => {
  let dictionaryList = store.getters.getSysDictList(props.code);

  if (Boolean(dictionaryList) && dictionaryList.length > 0) {
    list.value = dictionaryList;
  } else {
    const { data } = await store.dispatch('GetSysDictList', props.code);

    list.value = data;
  }
};

//监听函数
watch(
  () => props.modelValue,
  (newValue) => {
    val.value = newValue;
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);

onBeforeMount(() => {
  initData();
});

//监听函数
watch(
  () => val.value,
  (newValue) => {
    emits('update:modelValue', newValue);
    if (newValue && list.value && list.value.length > 0) {
      if (props.multiple) {
        // eslint-disable-next-line no-shadow
        let newList = [];

        newValue.forEach((element) => {
          const data = list.value.find((r) => r[props.valueName] === element);

          if (data) {
            newList.push(data);
          }
        });
        emits('change', newList);
      } else {
        emits(
          'change',
          list.value.find((r) => r[props.valueName] === newValue)
        );
      }
    }
  },
  {
    immediate: true, //watch会在创建后立即执行一次
    deep: true //监听深度嵌套的对象属性
  }
);
</script>
