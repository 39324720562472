import { JSEncrypt } from 'jsencrypt';

const publicKey = `${process.env.VUE_APP_PUBLIC_KEY}`,

  privateKey = `${process.env.VUE_APP_PRIVATE_KEY}`;

// RSA加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt();

  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对需要加密的数据进行加密
}

// RSA解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt();

  encryptor.setPrivateKey(privateKey);
  return encryptor.decrypt(txt);
}
