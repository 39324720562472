import { createApp } from 'vue';
import App from '@/App.vue';
const Vue = createApp(App);

import Vuex from 'vuex';
import getters from './getters';
import app from './modules/app';
import permission from './modules/permission';
import user from './modules/user';
import dictionary from './modules/dictionary';
import dataStore from './modules/dataStore';


Vue.use(Vuex);

// eslint-disable-next-line one-var
const store = new Vuex.Store({
  modules: {
    app,
    permission,
    user,
    dictionary,
    dataStore
  },
  getters
});

export default store;
